<template>
  <div class="home">
    <div class="home__hero container-fluid mx-0">
      <div class="row">
        <div class="col-12 col-md-5 home__hero-left py-5" style="background-color: #f6f9ff;"
          :class="{'pad-l': large }">
          <div class="d-flex flex-column align-items-center mt-5">
            <h3 class="home__hero__title">搜尋有興趣的線上課程</h3>
            <form class="d-flex position-relative home__searchForm col-12 col-md-3 mb-2" @submit.prevent="search">
              <button class="btn home__searchBtn position-absolute" type="submit" data-test="home__searchBtn" ><i class="fas fa-search"></i></button>
              <input class="form-control me-2 home__search" type="search"
                placeholder="搜尋" aria-label="Search" v-model="query" data-test="home__search">
            </form>
            <div class="d-flex justify-content-center mb-2 flex-wrap" style="min-height: 30px;">
              <div class="badge rounded-pill home__pill"
                v-for="item in hashTagsFeed"
                :key="item.id"
                @click="hashTagSearch(item.name)">
                <span>{{ item.name }}</span>
              </div>
            </div>
            <span class="text-start home__hero__txt">
              <i class="fas fa-bullseye me-2"></i>專業的師資教材<br>
              <i class="fas fa-bullseye me-2"></i>提供您全方位的影音學習環境<br>
              <i class="fas fa-bullseye me-2"></i>突破傳統的學習模式
            </span>
          </div>
        </div>
        <div class="col-12 col-md-7 py-5 d-flex home__hero-right flex-column justify-content-center"
          :class="{'pad-r': large }">
          <div class="home__imgfr mx-auto">
            <!-- 
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#home__hero-carousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#home__hero-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#home__hero-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../assets/images/hero.svg" alt="">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/hero.svg" alt="">
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/hero.svg" alt="">
                </div>
              </div>
            </div> -->
            <VueSlickCarousel :arrows="false" :dots="true" :slidesToShow="1" :autoplay="true">
              <img src="../assets/images/home_hero_1.jpg" alt="">
              <img src="../assets/images/home_hero_2.jpg" alt="">
              <img src="../assets/images/home_hero_3.jpg" alt="">
              <img src="../assets/images/home_hero_4.jpg" alt="">
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
    <section class="container-fluid py-5 px-0 position-relative" style="background-color: white;">
      <div class="container position-relative px-5">
        <h2 class="course__related mb-5">熱門課程</h2>
        <div class="container">
          <swiper class="swiper" :options="swiperOption1" v-if="hitData.length> 0">
            <swiper-slide v-for="(item, i) in hitData" :key="i" @click.prevent='$router.push(`/course/${item.id}`)'>
              <CourseCard :course="item"></CourseCard>
            </swiper-slide>
            
          </swiper>
          <div class="swiper-button-prev-1 swiper-btn d-flex justify-content-center align-items-center" slot="button-prev"> &lt; </div>
          <div class="swiper-button-next-1 swiper-btn d-flex justify-content-center align-items-center" slot="button-next"> &gt; </div>
          <div v-if="hitData.length == 0">
            <Loading :amount="4"></Loading>
          </div>
        </div>
      </div>
      <img src="../assets/images/Tophit.svg" alt="" width="120" class="course__related__img">
    </section>
    <section class="container-fluid py-5 px-0" style="background-color: white;">
      <div class="container position-relative px-5">
        <h2 class="course__related mb-5">精選課程</h2>
        <div class="container">
          <swiper class="swiper" :options="swiperOption2" v-if="data.length> 0">
            <swiper-slide v-for="(item, i) in data" :key="i" @click.prevent='$router.push(`/course/${item.id}`)' data-test="selectedCard">
              <CourseCard :course="item"></CourseCard>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev-2 swiper-btn d-flex justify-content-center align-items-center" slot="button-prev"> &lt; </div>
          <div class="swiper-button-next-2 swiper-btn d-flex justify-content-center align-items-center" slot="button-next"> &gt; </div>
        </div>
        <div v-if="data.length == 0">
          <Loading :amount="4"></Loading>
        </div>
      </div>
    </section>
    <div class="home__review py-5">
      <h3 class="course__related mb-5">學生們這麼說</h3>
      <div class="container">
        <div class="row">
          <template v-if="comments.length > 0">
            <div class="col-12 col-md-4" v-for="item in comments" :key="item.id">
              <div class="card border-0 mb-3" style="border-radius: 0rem;">
                <div class="card-body p-4 text-start d-flex flex-column">
                  <i class="fas fa-quote-left home__review__quote mb-2"></i>
                  <div class="card-right mb-3">
                    <strong class="fw-bold mb-3 d-block">{{ item.title }}</strong>
                    <p style="line-height: 1.4rem;">{{ item.comment }}</p>
                  </div>
                  <div class="card-photo me-4 d-flex align-items-center justify-content-end">
                    <div class="card-imgfr mb-2 me-3">
                      <img :src="item.avatar" alt="" class="rounded-circle card-header p-0">
                    </div>
                    <p class="course__review__user mb-2 me-2">{{ item.userName }}</p>
                    <i class="fas fa-quote-right home__review__quote"></i>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

    </div>
    <Meta :title ="title"  />
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import VueSlickCarousel from 'vue-slick-carousel'
import CourseCard from '../components/CourseCard';
import Loading from '../components/Loading';
import 'swiper/css/swiper.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    CourseCard,
    Loading,
    VueSlickCarousel
  },
  data() {
    return {
      query: '',
      data: [],
      hitData: [],
      title: '元學堂',
      large: 'false',
      swiperOption1: {
        autoplay: {
          delay: 5000
        },
        spaceBetween: 20,
        slidesPerView: 4,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next-1',
          prevEl: '.swiper-button-prev-1',
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          876: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
      swiperOption2: {
        autoplay: {
          delay: 4000
        },
        spaceBetween: 20,
        slidesPerView: 4,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next-2',
          prevEl: '.swiper-button-prev-2',
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          876: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
      selected: 6,
      comments: [],
      hashTagsFeed: [],
      selectedOrg: [],
      hotHitOrg: [],
    };
  },
  metaInfo: {
    title: "元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      const vm = this;
      vm.$http.get(`${vm.$API_PATH}/Course/Selected?Take=${vm.selected}`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        vm.$log.debug(res.data.data.selectedCourseList);
        vm.data = res.data.data.selectedCourseList;
        vm.selectedOrg = res.data.data.selectedCourseList;
        if (vm.data.length <= 6) {
          vm.data = [...res.data.data.selectedCourseList,
            ...res.data.data.selectedCourseList,
            ...res.data.data.selectedCourseList];
        }
        let page = Math.floor(Math.random() * 4) + 1;
        const query = {
          CourseName: '',
          CourseCategoryId: 0,
          OrderBy: 'late',
          Ordering: 'DESC',
          page: page,
        };
        return vm.$http.post(`${vm.$API_PATH}/Course/All`, query, {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer`,
          },
        })
      }).then((res) => {
        let temp = res.data.data.courseList;
        vm.hotHitOrg = res.data.data.courseList.filter((item, idx) => {
          vm.selectedOrg.forEach((el, i) => {
            if(el.id == item.id) {
              temp.splice(idx, 1);
            }
          })
          return temp;
        });
        vm.hitData = [...temp, 
          ...temp
        ];
      })
      .catch((err) => {
        vm.$router.push('/error');
        vm.$log.debug(err.response);
      });
    },
    getHitData() {
      let page = Math.floor(Math.random() * 4) + 1;
      const query = {
        CourseName: '',
        CourseCategoryId: 0,
        OrderBy: 'late',
        Ordering: 'DESC',
        page: page,
      };
      
      this.$http.post(`${this.$API_PATH}/Course/All`, query, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer`,
        },
      }).then((res) => {
        console.log(res.data);
        this.hitData = [...res.data.data.courseList, 
          ...res.data.data.courseList
        ];
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    search() {
      const query = {
        CourseName: this.query,
        CourseCategoryId: 1,
        OrderBy: 'lastest',
        Ordering: 'ASC',
        page: 1,
      };
      this.$http.post(`${this.$API_PATH}/Course/All`, query, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.data = res.data.data.courseList;
        this.$router.push({ path: '/all_courses', query: { keyword: `${this.query}` } });
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    getHashtags() {
      this.$http.get(`${this.$API_PATH}/Course/HashtagsFeed`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.hashTagsFeed = res.data.data.hashtags;
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    hashTagSearch(str) {
      this.query = str;
      this.$router.push({ path: '/all_courses', query: { keyword: `${this.query}` } });
    },
    getComments() {
      this.$http.get('/static/json/homeComments.json').then((res) => {
        const result = res.data;
        for (let i = 0; i < 3; i++) {
          const idx = Math.floor(Math.random() * result.length);
          this.comments.push(result[idx]);
          result.splice(idx, 1);
        }
        this.$log.debug(this.comments);
      }).catch((err) => {
        this.$log.debug(err.response);
      });
    },
  },
  mounted() {
    if (window.innerWidth >= 1800) {
      this.large = true;
    }
    window.addEventListener('resize', function (e) {
      if (window.innerWidth >= 1800) {
        this.large = true;
      } else {
        this.large = false;
      }
    })
  },
  created() {
    this.getData();
    this.getComments();
    this.getHashtags();
  },
};
</script>

<style lang="scss" scoped>
  .carousel-indicators {
    margin-bottom: -1rem;
  }
  .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #012970;
    border: none;
  }

  .course__related__img {
    transform: rotate(15deg);
    position: absolute;
    bottom: -5rem;
    right: 0rem;
  }
  .pad-l {
    padding-left: calc(50vw - 600px);
  }
  .pad-r {
    padding-right: calc(50vw - 800px);
  }
</style>
