<template>
  <div class="row load">
    <div class="col-12 col-sm-6 col-md-3 mb-4" v-for="idx in amount" :key="idx">
      <div class="courses__card">
        <div class="card bdrs-sm" >
          <div class="card-box"></div>
          <div class="card-content py-4 px-2">
            <h2 class="card-title"></h2>
            <h2 class="card-title"></h2>
            <p class="card-text card-author"></p>
            <p class="card-text card-rate"></p>
            <p class="card-text card-student"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: ['amount'],
};
</script>

<style lang="scss" scoped>

@mixin background {
  background: linear-gradient(
      110deg,
      transparent 40%,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 60%
    ),
    #6c757d;
  opacity: 0.3;
  background-size: 200% 100%;
  background-position: 120%;
  animation: 1.5s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position: -20%;
  }
}

.card {
  &-box {
    @include background;
    min-height: 200px;

  }
  &-title {
    @include background;
    min-height: 1.5rem;
    animation-delay: 0.08s;
    width: 95%;
    border-radius: 1rem;
  }
  &-text {
    @include background;
    min-height: 1rem;
    margin-bottom: 0.5rem;
    animation-delay: 0.1s;
    border-radius: 1rem;
  }
  &-author {
    width: 20%;
  }
  &-rate {
    width: 60%;
  }
  &-student {
     width: 30%;
  }
}

</style>
